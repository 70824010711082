<template>
    <vx-card title="Canvas - Picking">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="$route.params.status">
                <vs-tab label="New">
                    <tab-new></tab-new>
                </vs-tab>
                <vs-tab label="Process">
                    <tab-process></tab-process>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabNew from "./TabNew.vue";
import TabProcess from "./TabProcess.vue";
import TabComplete from "./TabComplete.vue";

export default {
    components: {
        TabNew,
        TabProcess,
        TabComplete,
    },
    data() {
        return {
            activeTab: "New",
            render: true,
            settingValue: "",
        }
    },
    mounted() {
        /* this.$vs.loading();
        this.$http
            .get("/api/wms/v1/canvas/loading/setting-value")
            .then((resp) => {
                if (resp.code == 200) {
                    this.settingValue = resp.data
                }

                this.render = false
                this.$nextTick(() => this.render = true)
                this.$vs.loading.close();
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            }); */
    },
    methods: {
        // 
    }
}
</script>